import React from "react";
import { Link } from "react-router-dom";

const FAQ = () => {
	const faqs = [
		{
			question: "Is Protocraft only for software engineers?",
			answer: "Protocraft is a general-purpose desktop AI studio. It provides tools that are useful for working with files, and its features are geared towards improving and accelerating the software development process.",
		},
		{
			question: "Why would I use this and not Copilot / Cursor / etc?",
			answer: "Protocraft is not an IDE, and it is not a replacement for Cursor, Copilot, or any other AI-enhanced development tool. Rather, Protocraft increases the speed of initially building and updating code from a higher vantage point, which allows you to then dive into the details in your code editor. An effective development workflow will use each tool for what they are best suited for. Protocraft can help you to iterate on creating and updating your code at the broader scale.",
		},
		{
			question: "What information do you collect?",
			answer: (
				<>
					<p className="mb-4">
						We store your email address related to your Protocraft license when you purchase a license. This information is only used to link your
						license to your account, and to send you emails so that you can login and receive a payment receipt, or to inform you of updates to
						Protocraft. We also store the date and time that a license was activated, but we do not collect or store personally-identifiable
						information.
					</p>
					<p>
						Any information we collect will never be shared with any third parties. You may use a burner email address for your Protocraft license,
						as long as you retain access to it so that we can assist you for any support requests. We do not collect or store any analytics or usage
						data from your use of the application, except for anonymized, aggregated count of daily active users. We do collect visitor stats for
						this website, and this data is anonymized and aggregated through Google Analytics.
					</p>
				</>
			),
		},
		{
			question: "What does my license grant me?",
			answer: "Your license grants you perpetual access for a single user / device to the Protocraft application in its current version, and any updates to Protocraft for 1 year from the date of purchase. A license is required for commercial, business, educational, and professional use of Protocraft, but a license is not needed during evaluation. It does not grant you access to or credits for any third-party AI models or services. You will need to provide your own API Keys for any third-party AI models you wish to use.",
		},
		{
			question: "Do I need to bring my own API Keys?",
			answer: "Yes, or you can use your own LLM and / or image generation software.",
		},
		{
			question: "Which AI Providers do you Support?",
			answer: "We currently support OpenAI, Anthropic, Google AI, OpenRouter, and Venice AI for LLMs, and OpenAI, HuggingFace, and Venice AI for image generation. Further, you can also bring your own LLM, as long as it cleanly works with the OpenAI library. We will add more providers in the future.",
		},
		{
			question: "Are there more features coming?",
			answer: (
				<>
					Yes, we are working on adding more features to Protocraft. If you have any feature requests, please contact us at{" "}
					<a href="mailto:support@protocraft.ai" className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900">
						support@protocraft.ai
					</a>
					, or reach out to us in the{" "}
					<a
						href="https://discord.gg/TTS9eeRmUy"
						target="_blank"
						rel="noopener noreferrer"
						className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900"
					>
						feature request channel in our Discord
					</a>
					.
				</>
			),
		},
		{
			question: "Where are my chats / images / files / data stored?",
			answer: "They are stored on your computer, in the user data directory for the Protocraft application.",
		},
		{
			question: "How are my API Keys / LLM Configurations Stored?",
			answer: (
				<>
					<p className="mb-4">
						They are stored in an encrypted configuration file locally, in your user data directory for the Protocraft application. This
						configuration is encrypted to prevent accidental leakage of your API Keys or LLM Configurations through cloud backups and other
						applications.
					</p>
					<p>
						Note: some installations of Windows do not support encryption by default - in those cases, the configuration is stored in plaintext in
						the same directory.
					</p>
				</>
			),
		},
		{
			question: "How do I export or import my workspaces / chats / chat history?",
			answer: "You can export your chat history by clicking the 'Data Export' button in the Settings page, in the Data Management section. You can import your chat history by clicking the 'Data Import' button in the same section.",
		},
		{
			question: "How do I wipe my saved API Keys / LLM Configurations?",
			answer: "You can wipe your saved API Keys / LLM Configurations by clicking the 'Reset Configuration' button in the Settings page, in the Data Management section. This will reset your configurations, and you will need to re-enter a valid license key to use Protocraft.",
		},
		{
			question: "What should I do if I encounter a bug?",
			answer: (
				<>
					If you encounter a bug while using the application, please send us an email at{" "}
					<a href="mailto:support@protocraft.ai" className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900">
						support@protocraft.ai
					</a>{" "}
					or reach out to us in the{" "}
					<a
						href="https://discord.gg/TTS9eeRmUy"
						target="_blank"
						rel="noopener noreferrer"
						className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900"
					>
						support channel in our Discord
					</a>
					. Provide a detailed description of the issue, including any error messages and steps to reproduce the bug, so our team can address it
					promptly.
				</>
			),
		},
		{
			question: "How can I contact support?",
			answer: (
				<>
					You can contact support by sending an email to{" "}
					<a href="mailto:support@protocraft.ai" className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900">
						support@protocraft.ai
					</a>
					, or reach out to us in the{" "}
					<a
						href="https://discord.gg/TTS9eeRmUy"
						target="_blank"
						rel="noopener noreferrer"
						className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900"
					>
						support channel in our Discord
					</a>
					.
				</>
			),
		},
	];

	const commonIssues = [
		{
			issue: "On Mac: Protocraft is slow and shows a white screen for a few seconds before starting",
			solution:
				"If you are using an M1 or newer chip, you will need to use the arm64 build of Protocraft. The x86 build runs on an emulation layer and is noticeably slower.",
		},
	];

	return (
		<div className="container mx-auto p-6 max-w-6xl">
			<h2 className="text-4xl font-bold text-center mb-8 text-purple-600 dark:text-purple-400">Frequently Asked Questions</h2>

			<div className="mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-1">
					{faqs.map((faq, index) => (
						<a
							key={`faq-${index}`}
							href={`#faq-${index}`}
							className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900 p-2 rounded transition duration-200"
						>
							{faq.question}
						</a>
					))}
					<a
						href="#common-issues"
						className="text-purple-600 hover:text-purple-800 hover:bg-purple-100 dark:hover:bg-purple-900 p-2 rounded transition duration-200"
					>
						Common Issues
					</a>
				</div>
			</div>

			<div className="space-y-8">
				{faqs.map((faq, index) => (
					<div key={`faq-${index}`} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
						<h3 id={`faq-${index}`} className="text-2xl font-semibold mb-4 text-purple-600 dark:text-purple-400">
							{faq.question}
						</h3>
						<div className="text-lg text-gray-700 dark:text-gray-300">{faq.answer}</div>
					</div>
				))}
			</div>

			<div className="mt-16" id="common-issues">
				<h2 className="text-3xl font-bold text-center mb-8 text-purple-600 dark:text-purple-400">Common Issues</h2>
				<ul className="space-y-4">
					{commonIssues.map((issue, index) => (
						<li key={index} className="bg-pink-100 dark:bg-pink-900 p-6 rounded-lg shadow-md">
							<strong className="text-xl text-pink-600 dark:text-pink-300">{issue.issue}</strong>
							<p className="text-lg mt-2 text-gray-700 dark:text-gray-300">{issue.solution}</p>
						</li>
					))}
				</ul>
			</div>

			<div className="mt-8 text-center">
				<Link to="/" className="text-purple-600 hover:text-purple-800 transition duration-200">
					← Back to Home
				</Link>
			</div>
		</div>
	);
};

export default FAQ;
